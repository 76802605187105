<template>
  <div>
    <navbar></navbar>
    <div className="container">
      <div className="row">
        <div style="color:black;font-size:30px" className="title">{{ content?.announcementTitle }}</div>
      </div>
      <div className="row">
        <div className="col-lg-2 dateRef">{{ content?.date }}</div>
      </div>
      <div className="row">
        <div className="col-lg-6 parRef" v-html="content?.announcementContent.markup"></div>
        <div className="col-lg-6 imgRef">
          <img v-if="content" :alt="content.announcementTitle"
               :src="`https://localhost:44339${content.announcementImg[0].url}`" style="width:100%; height:100%;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted} from 'vue';
import {useStore} from 'vuex';
import Navbar from "@/components/Utility/navbar.vue";

export default {
  name: 'ContentPage',
  components: {
    Navbar
  },
  props: ['id'],
  setup(props) {
    const store = useStore();
    const content = computed(() => store.getters.getItemById(Number(props.id)));

    onMounted(async () => {
      if (!content.value) {
        await store.dispatch('fetchItemById', Number(props.id));
      }
    });

    return {
      content
    };
  }
};
</script>

<style scoped>
.dateRef {
  color: black;
  font-weight: 500;
}

.parRef {
  text-align: left;
  color: black;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.imgRef {
  width: 850px;
  height: 510px;
}
</style>
