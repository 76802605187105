<template>
  <div>
    <h1 class="refTitle">İçerikler</h1>
    <div class="kap ortalayici">
      <div class="row ortalayici">
        <div v-for="(box, boxIndex) in items" :key="boxIndex" class="col-lg-4">
          <router-link
              v-if="box.imgAnnouncementCard[0].url"
              :to="{ name: 'ContentPage', params: { id: box.idAnnouncement } }"
              class="squareBox"
              :style="{ backgroundImage: `url(https://localhost:44339${box.imgAnnouncementCard[0].url})` }"
          >
            <div class="refText">{{ box.announcementTitle }}</div>
            <div class="refContext">{{ box.AnnouncementCardTitle }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'referencePage',
  setup() {
    const store = useStore();
    const items = computed(() => store.getters.items);

    onMounted(() => {
      store.dispatch('fetchItems');
    });

    return {
      items
    };
  }
};
</script>

<style scoped>
.squareBox {
  height: 400px;
  margin-top: 50px;
  background-size: cover;
  position: relative;
  display: block;
  transition: transform 0.3s ease;
}
.squareBox:hover {
  transform: scale(1.02);
}
.refText {
  text-align: left;
  padding: 50px 150px 0 50px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.refContext {
  text-align: left;
  padding: 0 150px 40px 50px;
  font-size: 15px;
  color: white;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 120px;
}
.refTitle {
  margin-top: 50px;
  color: black;
  font-size: 40px;
}
</style>
