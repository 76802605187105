import { createI18n } from 'vue-i18n';
import en from '@/locale/en.json';
import de from '@/locale/de.json';
import tr from '@/locale/tr.json';
const messages = {
    en,
    de,tr
};

const i18n = createI18n({
    locale: 'tr', // set locale
    fallbackLocale: 'tr', // set fallback locale
    messages, // set locale messages
});

export default i18n;
