// main.js
import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/font-awesome.min.css';
import '../src/assets/css/magnific-popup.css';
import '../src/assets/css/default.css';
import '../src/assets/css/style.css';
import '../src/assets/css/custom.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from '@/router/router';
import VueGtag from "vue-gtag";
import store from "@/store";
import i18n from './i18n';
library.add(faPhone);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(i18n);
app.use(router);
app.use(store); // Store'u ekliyoruz
app.use(VueGtag, { config: { id: "G-VRWLSVSQCV" } });
app.mount("#app");
