import { createStore } from 'vuex';
import UmbracoService from "@/components/services/umbraco.service";

export default createStore({
    state: {
        items: []
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        }
    },
    actions: {
        async fetchItems({ commit }) {
            try {
                const data = await UmbracoService.getContentItem();
                const items = data.items.map(item => item.properties);
                commit('SET_ITEMS', items); // commit'i burada kullanıyorsunuz
            } catch (error) {
                console.error('Error fetching content:', error);
            }
        },
        async fetchItemById({  state }, id) {
            if (!state.items.length) {
                await this.dispatch('fetchItems');
            }
            const item = state.items.find(item => item.idAnnouncement === id);
            return item;
        }
    },
    getters: {
        items: state => state.items,
        getItemById: (state) => (id) => {
            return state.items.find(item => item.idAnnouncement === id);
        }
    }
});
